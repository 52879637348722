var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',{staticClass:"fill-height"},[_c('v-sheet',{staticClass:"cr-calendar-main",style:({ height: _vm.calendarHeight })},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","day-format":_vm.getFormattedDay,"events":_vm.calendarEvents,"event-color":_vm.getEventBackgroundColor,"event-start":_vm.isMonthView() ? 'startForOrdering' : 'start',"event-timed":function (event) { return (_vm.isMonthView() ? true : _vm.isTimedEvent(event)); },"type":_vm.viewType,"event-ripple":false,"locale":_vm.$i18n.locale},on:{"click:more":_vm.clickMore,"mousedown:event":_vm.startEventDrag,"mousedown:day":_vm.startTime,"mousedown:time":_vm.startTime,"mousemove:day":_vm.moveTime,"mousemove:time":_vm.moveTime,"mouseup:day":_vm.endDrag,"mouseup:time":_vm.endDrag,"mouseenter:event":_vm.showSummaryMenu,"mouseleave:event":_vm.hideSummaryMenu,"change":_vm.updateRange},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([{key:"day-label-header",fn:function(ref){
var date = ref.date;
var day = ref.day;
var present = ref.present;
return [_c('div',{staticClass:"pa-1"},[_c('v-btn',{class:_vm.getDayTextColor(date, present),staticStyle:{"font-size":"14px"},attrs:{"small":"","fab":"","text":!present,"depressed":present,"color":_vm.getDayHeaderColor(date, present)},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.viewDay(date)}}},[_vm._v(" "+_vm._s(day)+" ")])],1)]}},{key:"day-label",fn:function(ref){
var date = ref.date;
var day = ref.day;
var present = ref.present;
return [_c('div',[_c('v-btn',{class:_vm.getDayTextColor(date, present),attrs:{"x-small":"","fab":"","text":!present,"depressed":present,"color":_vm.getDayHeaderColor(date, present)},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.viewDay(date)}}},[_vm._v(" "+_vm._s(day)+" ")])],1)]}},{key:"day-body",fn:function(ref){
var date = ref.date;
var week = ref.week;
var timeToY = ref.timeToY;
return [_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY(timeToY) })})]}},{key:"event",fn:function(ref){
var event = ref.event;
return [(event.isCreating)?_c('div',{staticClass:"cr-event cr-creating-event cr-editing-event elevation-5"},[(event.name)?_c('span',[_vm._v(" "+_vm._s(event.name)+" ")]):_c('span',{staticStyle:{"opacity":"0.9"}},[_vm._v(" "+_vm._s(_vm.getDateTimeSummary(event))+" ")])]):(_vm.isOrganEvent(event))?_c('div',{staticClass:"cr-event",class:{
                'cr-selected-event': _vm.isSelectedEvent(event)
              }},[_c('v-icon',{staticClass:"ml-n1",attrs:{"small":"","color":"white"}},[_vm._v(" mdi-domain ")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(event.source.owner.accountName))]),_c('span',{staticClass:"ml-1 overflow-hidden"},[_vm._v(_vm._s(event.name))])],1):_c('div',{staticClass:"cr-event",class:{
                'cr-selected-event': _vm.isSelectedEvent(event),
                'cr-dragging-event': _vm.isDraggingEvent(event),
                'cr-editing-event': _vm.isEditingEvent(event)
              },style:(event.waitForAction
                  ? {
                      border: '1px solid ' + event.calendar.color
                    }
                  : null)},[(_vm.isLeaveEvent(event))?_c('v-icon',{staticClass:"ml-n1",staticStyle:{"font-size":"18px"},attrs:{"color":!event.waitForAction && (!_vm.isMonthView() || event.isAllDay)
                    ? 'white'
                    : event.calendar.color}},[_vm._v(" mdi-airplane ")]):(_vm.isMonthView() && _vm.isTimedEvent(event))?_c('v-icon',{staticClass:"ml-n2 mr-n1",attrs:{"size":"22","color":event.calendar.color}},[_vm._v(" mdi-circle-medium ")]):_vm._e(),_c('span',{staticClass:"overflow-hidden",style:(!event.waitForAction && (!_vm.isMonthView() || event.isAllDay)
                    ? 'color:white'
                    : 'color:rgba(0, 0, 0, 0.87)')},[(_vm.isTimedEvent(event))?_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(_vm.getTimeText(event))+" ")]):_vm._e(),_vm._v(" "+_vm._s(event.name)+" ")])],1)]}}]),model:{value:(_vm.focusDate),callback:function ($$v) {_vm.focusDate=$$v},expression:"focusDate"}})],1)],1)],1),_c('EventMenu',{on:{"open:eventEditor":_vm.openEventEditorByMenu},model:{value:(_vm.showEventMenu),callback:function ($$v) {_vm.showEventMenu=$$v},expression:"showEventMenu"}}),(!_vm.showEventMenu && !_vm.dragEvent)?_c('EventSummaryMenu'):_vm._e(),_c('EventEditorMenu',{attrs:{"center-relative-to":_vm.editorCenterTo,"center-horizontally-only":""},model:{value:(_vm.showEventEditor),callback:function ($$v) {_vm.showEventEditor=$$v},expression:"showEventEditor"}}),(_vm.showMoreDialog)?_c('EventMoreDialog',{attrs:{"events":_vm.calendarEvents,"date":_vm.selectedDate},model:{value:(_vm.showMoreDialog),callback:function ($$v) {_vm.showMoreDialog=$$v},expression:"showMoreDialog"}}):_vm._e(),(_vm.showGroupEventMailConfirm)?_c('GroupEventMailConfirm',{attrs:{"isInvitation":_vm.isInvitation},on:{"confirm":_vm.groupEventMailConfirmCallback},model:{value:(_vm.showGroupEventMailConfirm),callback:function ($$v) {_vm.showGroupEventMailConfirm=$$v},expression:"showGroupEventMailConfirm"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }