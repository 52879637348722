<template>
  <div>
    <CalendarFrame ref="calendarFrame" />
    <v-overlay :value="showOverlay" z-index="20000">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <CalendarTutorial />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CalendarTutorial from "@/commons/views/tutorial/CalendarTutorial";
import CalendarFrame from "@/calendar/components/CalendarFrame.vue";

export default {
  components: {
    CalendarTutorial,
    CalendarFrame
  },
  computed: {
    ...mapGetters("cal", ["getShowLoadingOverlay"]),
    showOverlay() {
      return this.getShowLoadingOverlay;
    }
  },
  created() {
    this.setShowSplash(false);
  },
  methods: {
    ...mapActions("splash", ["setShowSplash"])
  }
};
</script>
