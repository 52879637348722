<template>
  <div>
    <v-row class="fill-height">
      <v-col class="fill-height">
        <v-sheet class="cr-calendar-main" :style="{ height: calendarHeight }">
          <v-calendar
            ref="calendar"
            v-model="focusDate"
            color="primary"
            :day-format="getFormattedDay"
            :events="calendarEvents"
            :event-color="getEventBackgroundColor"
            :event-start="isMonthView() ? 'startForOrdering' : 'start'"
            :event-timed="event => (isMonthView() ? true : isTimedEvent(event))"
            :type="viewType"
            :event-ripple="false"
            :locale="$i18n.locale"
            @click:more="clickMore"
            @mousedown:event="startEventDrag"
            @mousedown:day="startTime"
            @mousedown:time="startTime"
            @mousemove:day="moveTime"
            @mousemove:time="moveTime"
            @mouseup:day="endDrag"
            @mouseup:time="endDrag"
            @mouseenter:event="showSummaryMenu"
            @mouseleave:event="hideSummaryMenu"
            @change="updateRange"
            @mouseleave.native="cancelDrag"
          >
            <!-- 일/주 보기의 일 -->
            <template #day-label-header="{ date, day, present }">
              <div class="pa-1">
                <v-btn
                  small
                  fab
                  :text="!present"
                  :depressed="present"
                  :color="getDayHeaderColor(date, present)"
                  :class="getDayTextColor(date, present)"
                  style="font-size: 14px;"
                  @mousedown.stop=""
                  @click.stop="viewDay(date)"
                >
                  {{ day }}
                </v-btn>
              </div>
            </template>

            <!-- 월 보기의 일 -->
            <template #day-label="{ date, day, present }">
              <div>
                <v-btn
                  x-small
                  fab
                  :text="!present"
                  :depressed="present"
                  :color="getDayHeaderColor(date, present)"
                  :class="getDayTextColor(date, present)"
                  @mousedown.stop
                  @click.stop="viewDay(date)"
                >
                  {{ day }}
                </v-btn>
              </div>
            </template>

            <!-- 일 보기에서 현재 시간 라인 표시 -->
            <template #day-body="{ date, week, timeToY }">
              <div
                class="v-current-time"
                :class="{ first: date === week[0].date }"
                :style="{ top: nowY(timeToY) }"
              ></div>
            </template>

            <template #event="{ event }">
              <!-- 생성 중인 일정 -->
              <div
                v-if="event.isCreating"
                class="cr-event cr-creating-event cr-editing-event elevation-5"
              >
                <span v-if="event.name">
                  {{ event.name }}
                </span>
                <span v-else style="opacity: 0.9">
                  {{ getDateTimeSummary(event) }}
                </span>
              </div>
              <!-- 부서 일정 -->
              <div
                v-else-if="isOrganEvent(event)"
                class="cr-event"
                :class="{
                  'cr-selected-event': isSelectedEvent(event)
                }"
              >
                <v-icon small color="white" class="ml-n1">
                  mdi-domain
                </v-icon>
                <span class="ml-1">{{ event.source.owner.accountName }}</span>
                <span class="ml-1 overflow-hidden">{{ event.name }}</span>
              </div>
              <!-- 시간/종일/연차 이벤트 -->
              <div
                v-else
                class="cr-event"
                :class="{
                  'cr-selected-event': isSelectedEvent(event),
                  'cr-dragging-event': isDraggingEvent(event),
                  'cr-editing-event': isEditingEvent(event)
                }"
                :style="
                  event.waitForAction
                    ? {
                        border: '1px solid ' + event.calendar.color
                      }
                    : null
                "
              >
                <v-icon
                  v-if="isLeaveEvent(event)"
                  class="ml-n1"
                  :color="
                    !event.waitForAction && (!isMonthView() || event.isAllDay)
                      ? 'white'
                      : event.calendar.color
                  "
                  style="font-size: 18px"
                >
                  mdi-airplane
                </v-icon>
                <v-icon
                  v-else-if="isMonthView() && isTimedEvent(event)"
                  size="22"
                  class="ml-n2 mr-n1"
                  :color="event.calendar.color"
                >
                  mdi-circle-medium
                </v-icon>

                <span
                  class="overflow-hidden"
                  :style="
                    !event.waitForAction && (!isMonthView() || event.isAllDay)
                      ? 'color:white'
                      : 'color:rgba(0, 0, 0, 0.87)'
                  "
                >
                  <span v-if="isTimedEvent(event)" style="font-size: 11px">
                    {{ getTimeText(event) }}
                  </span>
                  {{ event.name }}
                </span>
              </div>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <EventMenu
      v-model="showEventMenu"
      @open:eventEditor="openEventEditorByMenu"
    />
    <EventSummaryMenu v-if="!showEventMenu && !dragEvent" />
    <EventEditorMenu
      v-model="showEventEditor"
      :center-relative-to="editorCenterTo"
      center-horizontally-only
    />

    <EventMoreDialog
      v-if="showMoreDialog"
      v-model="showMoreDialog"
      :events="calendarEvents"
      :date="selectedDate"
    />

    <GroupEventMailConfirm
      v-if="showGroupEventMailConfirm"
      v-model="showGroupEventMailConfirm"
      :isInvitation="isInvitation"
      @confirm="groupEventMailConfirmCallback"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import {
  CAL_CONSTANTS,
  CALENDAR_TYPE,
  EVENT_PLATFORM
} from "@/calendar/constant/calConstants";
import EventMoreDialog from "@/calendar/components/event/EventMoreDialog.vue";
import { getDateTimeSummary } from "@/calendar/utils/DateUtils";
import { makeEmptyEvent } from "@/calendar/utils/EventUtils";

import { getElementPosition } from "@/calendar/utils/ViewUtils";
import EventEditorMenu from "@/calendar/components/event/EventEditorMenu.vue";
import EventMenu from "@/calendar/components/event/EventMenu.vue";
import i18n from "@/_locales";
import EventSummaryMenu from "@/calendar/components/event/EventSummaryMenu.vue";
import GroupEventMailConfirm from "@/calendar/components/event/confirm/GroupEventMailConfirm.vue";

/*
 * # 월 보기 화면에서 사용자 소유의 일정을 우선적으로 표시하기 위하여 다음과 같은 작업을 수행합니다.
 * - 월 보기인 경우 모든 일정을 시간 일정으로 취급합니다.
 * - 일정 데이터를 가져올 때 사용자 소유 일정을 우선 배치하는 임의의 시간 필드( startForOrdering )를 설정합니다.
 * - 월 보기인 경우 startForOrdering 필드를 일정의 시작일 속성으로 설정합니다.
 *
 * # 유의 사항
 * startForOrdering 속성은 일정의 실제 시작 일시와 날짜만 동일하고 시간은 전혀 다른, 정렬을 위한 필드입니다.
 * 실제 일정 시간이 필요한 작업 시 해당 필드를 사용하면 안되며,
 * 드래그로 일정 생성, 일정 편집 등 시간이 연계되는 작업 시 함께 수정해야 할 수 있습니다.
 */
export default {
  components: {
    GroupEventMailConfirm,
    EventSummaryMenu,
    EventMenu,
    EventEditorMenu,
    EventMoreDialog
  },
  data: () => ({
    showEventMenu: false,
    showEventEditor: false,
    editorCenterTo: null,
    showMoreDialog: false,
    showGroupEventMailConfirm: false,
    isInvitation: false,
    groupEventMailConfirmCallback: null,
    selectedDate: null,
    focusDate: "",
    viewType: "month",
    // height 자동 조절용 필드
    windowHeight: window.innerHeight,
    calendarHeight: 0,
    // ------------------------------
    // 일정 클릭 & 드래그 관련 필드
    dragEvent: null,
    dragEventMoved: false,
    dragTime: {
      point: null,
      start: null,
      end: null
    },
    isDragEventWritable: true,
    // ------------------------------
    createStart: null,
    ready: false
  }),
  computed: {
    ...mapState("cal", [
      "currentStartDt",
      "tempEvents",
      "editEvent",
      "eventEditor",
      "eventMenu",
      "activeEventEditor"
    ]),
    ...mapGetters("extCal", ["getExternalEventsInDateRange"]),
    ...mapGetters("cal", ["getEventsInDateRange", "getMyCalendars"]),
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    holidays() {
      return this.getEventsInDateRange.filter(event => event.detail.isRest);
    },
    calendarEvents() {
      return this.tempEvents
        .concat(this.getEventsInDateRange)
        .concat(this.getExternalEventsInDateRange);
    }
  },
  watch: {
    "$route.params": {
      handler: function() {
        this.setCalendarFrameData();
      },
      deep: true,
      immediate: true
    },
    windowHeight() {
      this.calculateCalendarHeight();
    },
    viewType() {
      this.scrollToTime();
      this.updateTime();
    },
    "editEvent.start": {
      handler: function() {
        this.$nextTick(() => {
          this.updateCreatingEventPosition();
          if (!this.eventEditor.fixed) {
            this.updateEditorPosition();
          }
        });
      }
    },
    activeEventEditor() {
      if (this.activeEventEditor !== "CalendarFrame") {
        this.showEventEditor = false;
      }
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
    this.ready = true;
    this.setCalendarFrameData();
    this.scrollToTime();
    this.updateTime();
    this.$nextTick(() => {
      window.addEventListener("focus", this.onFocus);
      window.addEventListener("resize", this.onResize);
      this.calculateCalendarHeight();
    });
    this.SET_CREATE_EVENT_FUNC(this.createEventOnFocusedDate);
  },
  updated() {
    this.updateDailyHeaderHeight();
  },
  beforeDestroy() {
    this.SET_CALENDARS([]);
    this.clearSirTeamEvents();
    this.clearExternalEvents();
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getDateTimeSummary,
    ...mapMutations("cal", [
      "SET_CALENDARS",
      "SET_DATE_RANGE",
      "SET_CREATE_EVENT_FUNC",
      "UPDATE_EVENT_TIME",
      "SET_EVENT_MENU",
      "SET_EVENT_SUMMARY_MENU",
      "SET_EDIT_EVENT",
      "SET_EVENT_EDITOR",
      "SET_ACTIVE_EVENT_EDITOR",
      "REMOVE_EDIT_EVENT",
      "SET_CALENDAR_TITLE"
    ]),
    ...mapMutations("cal", {
      clearSirTeamEvents: "DELETE_EVENTS_EXCLUDING_MONTHS"
    }),
    ...mapMutations("extCal", {
      clearExternalEvents: "DELETE_EVENTS_EXCLUDING_MONTHS"
    }),
    ...mapActions("cal", ["loadSirTeamEvents", "updateEventToServer"]),
    ...mapActions("extCal", ["loadExternalEvents"]),
    ...mapActions("snackbar", ["openSnackbar"]),
    nowY(timeToY) {
      return this.cal ? timeToY(this.cal.times.now) + "px" : "-10px";
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);
      this.$nextTick(() => this.cal.scrollToTime(first));
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
    onFocus() {
      if (this.currentStartDt === 0) return;

      this.loadExternalEvents();
      this.loadSirTeamEvents(true);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    calculateCalendarHeight() {
      const mainHeight = document.getElementsByClassName("v-main")[0]
        .clientHeight;
      const calendarHeight = mainHeight - 64;
      this.calendarHeight = calendarHeight + "px";
    },
    /**
     * 일/주/4일 보기 시 요일 헤더의 높이를 동적으로 설정합니다.
     * 요일 헤더 컨테이너에 최대 높이, 스크롤 적용 시 우측 경계선을 올바르게 표시하기 위한 작업입니다.
     */
    updateDailyHeaderHeight() {
      if (this.isMonthView()) return;

      const elements = document.querySelectorAll(".v-calendar-daily_head-day");

      let maxHeight = 0;
      elements.forEach(element => {
        element.style.height = "max-content";
        maxHeight = Math.max(maxHeight, element.clientHeight);
      });
      elements.forEach(element => (element.style.height = maxHeight + "px"));
    },
    /**
     * 월 보기에서 드래그 일정 생성 시 일정을 맨 위로 올립니다.
     */
    updateCreatingEventPosition() {
      if (!this.isMonthView()) return;

      const elements = document.querySelectorAll(".cr-creating-event");
      if (!elements.length) {
        /*
         * Vue의 재사용 매커니즘이 생성 중인 일정에 적용하는 클래스를 잔존시킬 수 있습니다.
         * 이를 방지하기 위해 생성 중인 일정이 없는 경우 클래스를 정리합니다.
         */
        document
          .querySelectorAll(".cr-creating-event-container")
          .forEach(element => {
            element.classList.remove("cr-creating-event-container");
          });
        return;
      }

      for (const element of elements) {
        const vCalendarEvent = element.parentElement;
        if (!vCalendarEvent.classList.contains("cr-creating-event-container")) {
          vCalendarEvent.classList.add("cr-creating-event-container");
        }
      }
    },
    /**
     * 일정 편집기 위치를 편집 중인 일정의 옆으로 이동합니다.
     */
    updateEditorPosition() {
      const elements = document.querySelectorAll(".cr-editing-event");
      if (!elements.length) return;

      // 일 보기에서 일정 엘리먼트 기준으로 편집기를 표시하면
      // 화면 끝에 표시되어 보기 불편하기 때문에 화면 중앙에 표시합니다.
      this.editorCenterTo =
        this.viewType === "day" ? ".cr-calendar-main" : null;

      // 여러 주에 걸치는 일정 등 일정 엘리먼트가 여러 개 존재할 수 있습니다.
      // 이때 마지막 일정 옆에 일정 편집기를 표시합니다.
      const vEventElement = elements[elements.length - 1].parentElement;
      this.SET_EVENT_EDITOR({
        ...getElementPosition(vEventElement),
        isExceptionRequest: this.editEvent.isCreating
          ? false
          : this.eventEditor.isExceptionRequest,
        fixed: this.eventEditor.fixed
      });
    },
    getDayHeaderColor(date, present) {
      if (!this.isDateInCalendarMonth(date)) return "grey";
      if (present) return this.isHoliday(date) ? "grey lighten-3" : "primary";
      if (this.isHoliday(date)) return "red";
      return "black";
    },
    getDayTextColor(date, present) {
      if (present) return this.isHoliday(date) ? "red--text" : "white--text";
      return "";
    },
    getEventBackgroundColor(event) {
      if (event.waitForAction) return "white";
      if (event.isCreating) return event.calendar.color;

      if (
        this.isMonthView() &&
        this.isTimedEvent(event) &&
        !this.isOrganEvent(event)
      ) {
        return "transparent";
      }

      return this.isDraggingEvent(event)
        ? this.parseColorToRgba(event.calendar.color, 0.7)
        : event.calendar.color;
    },
    parseColorToRgba(colorCode, opacity) {
      const rgb = parseInt(colorCode.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    },
    viewDay(date) {
      this.$router.push({
        name: "cal_frame",
        params: {
          type: "day",
          focus: date
        }
      });
    },
    clickMore(event) {
      this.selectedDate = event.date;
      this.showMoreDialog = true;
    },
    openEventMenu({ event, element }) {
      if (event.isCreating || this.showEventEditor) return;

      const open = () => {
        this.SET_EVENT_MENU({
          event,
          element
        });
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.showEventMenu = true))
        );
      };

      if (this.showEventMenu) {
        this.showEventMenu = false;
        if (
          event.id !== this.eventMenu.event.id ||
          event.start.getTime() !== this.eventMenu.event.start.getTime()
        ) {
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        }
      } else {
        open();
      }
    },
    showSummaryMenu({ nativeEvent, event }) {
      if (event.isCreating) return;

      this.SET_EVENT_SUMMARY_MENU({
        event,
        element: nativeEvent.target
      });
    },
    hideSummaryMenu() {
      this.SET_EVENT_SUMMARY_MENU({
        event: null,
        element: null
      });
    },
    createEventOnFocusedDate() {
      if (this.showEventEditor) return;

      const date = this.focusDate
        ? this.focusDate
        : moment().format("YYYY-MM-DD");
      const nearestTime = CAL_CONSTANTS.findNearestTime(moment(), "HH:mm");
      const startTime = moment(`${date} ${nearestTime}`, "YYYY-MM-DD HH:mm");

      this.REMOVE_EDIT_EVENT();
      this.SET_EDIT_EVENT({
        isCreating: true,
        ...makeEmptyEvent(
          this.getMyCalendars.find(
            calendar => calendar.type === CALENDAR_TYPE.DEFAULT
          ),
          {
            start: startTime.toDate(),
            end: startTime.add(1, "hours").toDate(),
            isAllDay: false
          }
        )
      });

      this.openEventEditor();
    },
    openEventEditorByMenu({ event, element, isExceptionRequest }) {
      this.SET_EVENT_EDITOR({
        ...getElementPosition(element),
        isExceptionRequest,
        fixed: false
      });
      this.REMOVE_EDIT_EVENT();
      this.SET_EDIT_EVENT(event);
      this.openEventEditor();
    },
    openEventEditor() {
      this.SET_EVENT_MENU({
        event: null,
        element: null
      });

      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.SET_ACTIVE_EVENT_EDITOR("CalendarFrame");
            this.showEventEditor = true;
          })
        );
      };

      if (this.showEventEditor) {
        this.showEventEditor = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    updateRange({ start, end }) {
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);

      this.SET_DATE_RANGE({ startDt: min.getTime(), endDt: max.getTime() });

      let title = i18n.d(min, {
        year: "numeric",
        month: "long"
      });
      if (min.getMonth() !== max.getMonth()) {
        title +=
          " - " +
          i18n.d(max, {
            year: "numeric",
            month: "long"
          });
      }
      this.SET_CALENDAR_TITLE(title);

      this.loadExternalEvents();
      this.loadSirTeamEvents();
    },
    // ------------ Drag and Drop 관련 이벤트들 start ---------------
    startEventDrag({ event, nativeEvent }) {
      if (nativeEvent.button !== 0 || event.isCreating || this.showEventEditor)
        return;
      if (event) this.dragEvent = event;

      /*
       * 일 보기의 종일 일정은 드래그 방지
       * 종일 <-> 시간 일정 간 전환 작업이 안정적이지 않아 드래그를 방지합니다.
       */
      if (this.isDayViewDrag()) {
        this.isDragEventWritable = false;
        return;
      }

      if (event) {
        this.dragTime.start = event.start.getTime();
        this.dragTime.end = event.end.getTime();
        this.dragTime.point = null;
        this.isDragEventWritable =
          !this.isOrganEvent(event) && event.calendar.privilege === 3;
      }
    },
    startTime(tms) {
      if (tms.nativeEvent.button !== 0) return;

      const isMore = tms.nativeEvent.target.classList.contains("v-event-more");

      if (!this.isDragEventWritable || isMore) return;

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime.point === null) {
        this.dragTime.point = mouse - this.dragEvent.start;
      } else if (!this.showEventEditor && this.editEvent === null) {
        /*
         * 일정 편집기가 열려 있을 때 메뉴를 닫음과 동시에 일정 생성을 시작하면
         *  일정 메뉴 등 다른 처리와 충돌이 발생할 수 있습니다.
         * 따라서, 일정 편집기가 닫혀있는 경우에만 일정 생성을 시작합니다.
         */
        const dateTime = moment(this.roundTime(mouse));
        if (this.isMonthView()) {
          const nextHalfHour = CAL_CONSTANTS.getNextHalfHour();
          dateTime.set({
            hour: nextHalfHour.hours(),
            minute: nextHalfHour.minutes()
          });
        }
        // 기본적으로 시간 일정으로 생성하며, 월 보기가 아닐 때 날짜 영역을 클릭한 경우에만 종일 일정으로 생성합니다.
        const isAllDay = !this.isMonthView() && tms.time?.length === 0;
        this.createStart = dateTime.toDate().getTime();

        this.REMOVE_EDIT_EVENT();
        this.SET_EDIT_EVENT({
          isCreating: true,
          ...makeEmptyEvent(
            this.getMyCalendars.find(
              calendar => calendar.type === CALENDAR_TYPE.DEFAULT
            ),
            {
              start: dateTime.toDate(),
              end: dateTime.add(1, "hours").toDate(),
              isAllDay
            }
          )
        });
      } else if (this.showEventEditor && this.editEvent !== null) {
        const dateTime = moment(this.roundTime(mouse));
        if (this.isMonthView()) {
          const nextHalfHour = CAL_CONSTANTS.getNextHalfHour();
          dateTime.set({
            hour: nextHalfHour.hours(),
            minute: nextHalfHour.minutes()
          });
        }
        // 기본적으로 시간 일정으로 생성하며, 월 보기가 아닐 때 날짜 영역을 클릭한 경우에만 종일 일정으로 생성합니다.
        const isAllDay = !this.isMonthView() && tms.time?.length === 0;
        this.createStart = dateTime.toDate().getTime();
        const newStart = dateTime.toDate();
        const newEnd = dateTime.add(1, "hours").toDate();

        if (
          this.editEvent.start.getTime() !== newStart.getTime() ||
          this.editEvent.end.getTime() !== newEnd.getTime()
        ) {
          this.UPDATE_EVENT_TIME({
            event: this.editEvent,
            start: newStart,
            end: newEnd,
            isAllDay
          });
        }
      }
    },
    moveTime(tms) {
      if (this.dragEvent && this.dragTime.point !== null) {
        const mouse = this.toTime(tms);
        const duration = this.dragEvent.end - this.dragEvent.start;
        const newStartTime = mouse - this.dragTime.point;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;

        if (
          this.dragEvent.start.getTime() !== newStart &&
          this.dragEvent.end.getTime() !== newEnd
        ) {
          this.dragEventMoved = true;

          this.UPDATE_EVENT_TIME({
            event: this.dragEvent,
            start: moment(newStart).toDate(),
            end: moment(newEnd).toDate()
          });
        }
      } else if (this.editEvent && this.createStart !== null) {
        const time = moment({
          year: tms.year,
          month: tms.month - 1,
          day: tms.day,
          hour: tms.hour,
          minute: tms.minute
        });
        if (this.isMonthView()) {
          const now = moment();
          time.set({
            hour: now.hours(),
            minute: now.minutes()
          });
        }
        const mouse = time.toDate().getTime();
        const mouseRounded = this.roundTime(mouse, false);
        const newStart = moment(Math.min(mouseRounded, this.createStart));
        const newEnd = moment(Math.max(mouseRounded, this.createStart));
        if (newStart.isSame(newEnd, "minute")) {
          newEnd.add(1, "hours");
        }
        const isAllDay =
          !newStart.isSame(newEnd, "day") ||
          (!this.isMonthView() && tms.time?.length === 0);

        if (
          this.editEvent.start.getTime() !== newStart.toDate().getTime() ||
          this.editEvent.end.getTime() !== newEnd.toDate().getTime()
        ) {
          this.UPDATE_EVENT_TIME({
            event: this.editEvent,
            start: newStart.toDate(),
            end: newEnd.toDate(),
            isAllDay
          });
        }
      }
    },
    endDrag(tms, nativeEvent) {
      // 기존 이벤트 드래그로 이동
      if (this.dragEvent) {
        if (this.isDragEventWritable && this.isDragged()) {
          this.updateDraggedEvent();
        } else if (!this.isDragEventWritable && this.isTriedDrag(tms)) {
          if (!this.isDayViewDrag()) {
            this.openSnackbar({
              message:
                this.dragEvent.platform === EVENT_PLATFORM.SIRTEAM
                  ? this.$t("calendar.131")
                  : this.$t("calendar.외부_오류.일정_수정_불가"),
              type: "ERROR"
            });
          }
        } else {
          this.openEventMenu({
            event: this.dragEvent,
            element: nativeEvent.target
          });
        }
      }
      // 드래그로 이벤트 생성
      else if (this.editEvent && !this.showEventEditor) {
        this.openEventEditor();

        /*
         * 이 위치에서 반환하지 않으면 requestAnimationFrame으로 일정 편집기를 여는 시점과,
         * cancelDrag 메서드가 일정 편집기를 닫는 시점이 충돌하여 오작동이 발생합니다.
         */
        this.createStart = null;
        return;
      }

      this.cancelDrag();
    },
    cancelDrag() {
      this.revertDraggedEvent();
      if (!this.showEventEditor) {
        this.REMOVE_EDIT_EVENT();
      }

      this.dragEvent = null;
      this.dragEventMoved = false;
      this.dragTime.start = null;
      this.dragTime.end = null;
      this.dragTime.point = null;
      this.isDragEventWritable = true;
      this.createStart = null;
    },
    revertDraggedEvent() {
      // 드래그한 일정을 원래 시간으로 되돌립니다.
      if (this.dragEvent && this.dragEventMoved) {
        this.UPDATE_EVENT_TIME({
          event: this.dragEvent,
          start: moment(this.dragTime.start).toDate(),
          end: moment(this.dragTime.end).toDate()
        });
      }
    },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    isDraggingEvent(event) {
      return (
        this.dragEventMoved &&
        this.dragEvent.id === event.id &&
        this.dragEvent.start.getTime() === event.start.getTime()
      );
    },
    isDragged() {
      return this.dragTime.start - this.dragEvent.start.getTime() !== 0;
    },
    isTriedDrag(tms) {
      // 쓰기 권한이 없는 이벤트는 드래그로 이동되지 않으므로 마우스 위치의 시간 정보를 기준으로 비교합니다.
      const mouse = this.toTime(tms);
      const mouseRounded = moment(this.roundTime(mouse));
      const start = moment(this.dragEvent.start);
      const end = moment(this.dragEvent.end);

      return !mouseRounded.isBetween(
        start,
        end,
        tms.hasTime ? "minute" : "day",
        "[]"
      );
    },
    updateDraggedEvent() {
      let detail = { ...this.dragEvent?.detail };
      detail.isAllDay = this.dragEvent?.isAllDay;

      const startDate = CAL_CONSTANTS.convertDateToMomentDate(
        this.dragEvent.start,
        detail.isAllDay
      );

      const endDate = CAL_CONSTANTS.convertDateToMomentDate(
        this.dragEvent.end,
        detail.isAllDay
      );

      detail.dtStart = startDate.valueOf();
      detail.dtEnd = endDate.valueOf();

      const param = {
        calendarId: this.dragEvent.calendar.id,
        eventUId: this.dragEvent.id,
        detail: detail,
        confirmedSendMail: false
      };

      // 반복 이벤트를 드래그 할 시, 해당 드랍된 날짜로 예외 이벤트 생성
      if (this.dragEvent.isRecurring) {
        let exDate;
        let date = moment(this.dragTime.start);
        if (this.dragEvent.isAllDay) {
          exDate = date.format("YYYYMMDD");
        } else {
          exDate = date.format("YYYYMMDDTHHmmss");
        }
        param.isExceptionRequest = true;
        param.exDate = exDate;
      }

      param.owner = this.dragEvent.source.owner;
      this.isInvitation = this.dragEvent.invited;
      param.isOrganizer = !this.isInvitation;

      if (this.dragEvent.attendees.length > 0) {
        this.groupEventMailConfirmCallback = confirmedSendMail => {
          param.confirmedSendMail = confirmedSendMail;
          this.updateEventToServer(param);
        };
        this.showGroupEventMailConfirm = true;
      } else {
        this.updateEventToServer(param);
      }
    },
    // ------------ Drag and Drop 관련 이벤트들 end ---------------
    setCalendarFrameData() {
      this.focusDate = this.$route.params.focus;
      this.viewType = this.$route.params.type || "month";
    },
    getFormattedDay(item) {
      return item.day;
    },
    getTimeText(event) {
      return moment(event.start).format("A h:mm");
    },
    isSelectedEvent(event) {
      return (
        this.showEventMenu &&
        event.id === this.eventMenu.event?.id &&
        event.start.getTime() === this.eventMenu.event.start.getTime()
      );
    },
    isEditingEvent(event) {
      return (
        this.editEvent &&
        event.id === this.editEvent.id &&
        event.start.getTime() === this.editEvent.start.getTime()
      );
    },
    isTimedEvent(event) {
      return !event.isAllDay;
    },
    isOrganEvent(event) {
      return event.calendar?.type === "ORGANIZATION";
    },
    isDateInCalendarMonth(date) {
      if (!this.cal) return false;

      return moment(date).isBetween(
        this.cal.lastStart.date,
        this.cal.lastEnd.date,
        "day",
        "[]"
      );
    },
    isHoliday(date) {
      const dt = moment(date);
      return (
        dt.weekday() === 0 ||
        this.holidays.some(holiday =>
          dt.isBetween(holiday.start, holiday.end, "day", "[]")
        )
      );
    },
    isMonthView() {
      return this.viewType === "month";
    },
    isLeaveEvent(event) {
      return event.detail?.isLeave;
    },
    isDayViewDrag() {
      return this.viewType === "day" && this.dragEvent?.isAllDay;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "vuetify/src/styles/styles.sass";

/* 캘린더 */
.v-calendar {
  border-width: 0 !important;
}

.v-calendar ::v-deep {
  /* 요일 헤더 영역 */
  .v-calendar-daily__head {
    max-height: 220px;
    overflow-y: auto;
    border-bottom: solid 1px #e0e0e0;

    /* 스크롤 기능은 제공, 스크롤 바는 미표시 */
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .v-calendar-daily_head-day {
    height: max-content;
    border-bottom: none;
  }

  .v-calendar-daily_head-day-label {
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 4;
  }

  /* 월의 요일 해더 */
  .v-calendar-weekly__head {
    padding-top: 1px;
    height: auto;
  }

  .v-calendar-weekly__head-weekday {
    display: table-cell;
    width: calc(100% / 7);
    background-color: transparent !important;
    color: #333 !important;
  }

  /*  하단 구분선 */
  .v-calendar-weekly__week:last-child .v-calendar-weekly__day {
    border-bottom: 0 solid;
  }

  /* 월간 : 지난달 */
  .v-calendar-weekly__week .v-calendar-weekly__day.v-outside {
    background-color: transparent;
  }

  /* 월간 날짜(D) */
  .v-calendar-weekly__day-label {
    margin-top: 0;
    padding: 2px;

    .v-btn--fab.v-size--x-small {
      width: 28px;
      height: 28px;
    }
  }
}

/* 이벤트 */
.v-calendar ::v-deep {
  .v-event {
    overflow: visible;
    border-radius: 4px;
    margin-left: 1px;

    :hover::after {
      border-radius: 4px;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.11;
      background-color: map-get($blue-grey, darken-3);
    }
  }

  .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
  }

  /* 월간 :: 이벤트 더보기 */
  .v-event-more {
    position: absolute;
    left: 1px;
    right: 1px;
    bottom: 0;
    font-weight: normal;
    text-align: center;
    padding-top: 2px;
    border-radius: 4px;
    background-color: map-get($grey, lighten-3) !important;

    &:hover {
      background-color: map-get($grey, lighten-2) !important;
    }
  }

  .cr-event {
    overflow: hidden;
    display: flex;
    height: 100%;
    border-radius: 4px;
    padding: 0 8px;
    line-height: 20px;

    .v-icon {
      height: 20px;
      line-height: 20px;
      transition: none;
    }
  }

  // 선택 상태인 이벤트
  .cr-selected-event {
    box-shadow: map-get($shadow-key-umbra, 5), map-get($shadow-key-penumbra, 5),
      map-get($shadow-key-ambient, 5);
  }

  // 드래그 중인 이벤트
  .cr-dragging-event {
    opacity: 0.7;
    box-shadow: map-get($shadow-key-umbra, 4), map-get($shadow-key-penumbra, 4),
      map-get($shadow-key-ambient, 4);
  }

  // 생성 중인 이벤트
  .cr-creating-event-container {
    position: absolute;
    top: 32px;
    z-index: 3;
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
