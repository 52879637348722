<template>
  <v-menu
    ref="menu"
    v-model="show"
    :activator="element"
    offset-x
    offset-y
    offset-overflow
    rounded="lg"
    open-on-hover
    open-delay="200"
    close-delay="200"
    content-class="elevation-6"
    min-width="280px"
    max-width="280px"
    z-index="1001"
  >
    <!-- 일정 편집기에 가려지지 않도록 z-index + 1 -->
    <v-card v-if="event">
      <v-card-title>
        <span class="text-subtitle-1 text-truncate">
          {{ event.name }}
        </span>
      </v-card-title>
      <v-card-subtitle class="text-subtitle-2">
        <div class="text-truncate">
          {{ getFormattedDateTimeText(event) }}
        </div>
        <!-- 주최/참석자 정보 -->
        <div v-if="event.attendees.length" class="text-truncate">
          {{ $t("calendar.주최자") }}
          {{
            event.organizer.name ? event.organizer.name : event.organizer.email
          }}
          ·
          {{ $t("calendar.64", { value: event.attendees.length }) }}
        </div>
      </v-card-subtitle>
      <v-card-text class="mt-n3 d-flex align-center justify-space-between">
        <IconField
          x-small
          icon="mdi-circle"
          :color="event.calendar.color"
          spacing="sm"
        >
          <IconFieldContent class="ml-n4">
            {{ event.calendar.title }}
            <span v-if="isOrganCalendar(event.calendar)">
              · {{ event.source.owner.accountName }}
            </span>
          </IconFieldContent>
        </IconField>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from "vuex";
import { getFormattedDateTimeText } from "@/calendar/utils/DateUtils";
import IconField from "@/calendar/components/common/IconField.vue";
import { isOrganCalendar } from "@/calendar/utils/CalendarUtils";
import IconFieldContent from "@/calendar/components/common/IconFieldContent.vue";

export default {
  components: { IconFieldContent, IconField },
  computed: {
    ...mapState("cal", ["eventSummaryMenu"])
  },
  data() {
    return {
      show: false,
      element: null,
      event: null,
      reset: false
    };
  },
  watch: {
    show() {
      if (this.reset && !this.show) {
        this.reset = false;
        this.element = null;
        this.event = null;
      }
    },
    eventSummaryMenu: {
      /**
       * 엘리먼트 변경 시 v-menu의 open-on-hover( open-delay ) 동작을 직접 호출합니다.
       * open-on-hover는 activator 변경 시 그 이후에 마우스가 호버돼야 하지만,
       * 일정 요약 메뉴는 마우스가 이미 일정 요소에 호버된 상태이므로 직접 호출해야 합니다.
       */
      handler() {
        /*
         * 엘리먼트를 null로 설정하면 데이터가 초기화되도록 플래그를 설정합니다.
         * 그렇지 않으면 드래그로 일정 생성 시 Vue의 재사용 매커니즘으로 인해 생성 중인 일정에 요약 메뉴가 표시될 수 있습니다.
         */
        if (!this.eventSummaryMenu.element) {
          this.reset = true;
          return;
        }

        this.reset = false;
        if (this.show && this.eventSummaryMenu.element === this.element) return;

        const open = () => {
          this.element = this.eventSummaryMenu.element;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => {
              // requestAnimationFrame 중 마우스가 엘리먼트를 떠난 경우 메뉴를 열지 않습니다.
              if (!this.eventSummaryMenu.element) return;

              // 메뉴가 변경된 엘리먼트의 위치에 표시된 후 일정 데이터를 교체합니다.
              this.$refs.menu.runDelay("open", () => {
                this.event = this.eventSummaryMenu.event;
                this.show = true;
              });
            })
          );
        };

        if (this.show) {
          this.show = false;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => {
              open();
            })
          );
        } else {
          open();
        }
      },
      deep: true
    }
  },
  methods: {
    isOrganCalendar,
    getFormattedDateTimeText
  }
};
</script>
