<template>
  <v-dialog :value="value" @input="updateShow" width="580px" scrollable>
    <v-card rounded>
      <v-card-title>
        {{ title }}
        <v-spacer />
        <v-btn class="mr-n2" icon @click="updateShow(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="px-2">
        <!-- 내 일정 -->
        <template v-if="userEvents.length">
          <div :class="[headerClass]">
            {{ $t("calendar.143") }}
            <v-divider inset />
          </div>
          <EventList :events="userEvents" @click-item="openEventMenu" />
        </template>

        <!-- 공유 일정 -->
        <template v-if="sharedEvents.length">
          <div :class="[headerClass]">
            {{ $t("calendar.146") }}
            <v-divider inset />
          </div>
          <EventList :events="sharedEvents" @click-item="openEventMenu" />
        </template>

        <!-- 부서 일정 -->
        <template v-if="organEvents.length">
          <div :class="[headerClass]">
            {{ $t("calendar.144") }}
            <v-divider inset />
          </div>
          <EventList
            :events="organEvents"
            @click-item="openEventMenu"
            is-organ-event
          />
        </template>

        <!-- 구글 일정 -->
        <template v-if="googleEvents.length">
          <div :class="[headerClass]">
            {{ $t("calendar.외부_일정") }}
            <v-divider inset />
          </div>
          <EventList :events="googleEvents" @click-item="openEventMenu" />
        </template>
      </v-card-text>
    </v-card>

    <EventMenu v-model="showEventMenu" @open:eventEditor="openEventEditor" />
    <EventEditorMenu v-model="showEventEditor" />
  </v-dialog>
</template>

<script>
import moment from "moment/moment";
import { mapGetters, mapMutations } from "vuex";
import {
  CALENDAR_TYPE,
  EVENT_PLATFORM
} from "@/calendar/constant/calConstants";
import EventEditorMenu from "@/calendar/components/event/EventEditorMenu.vue";
import EventMenu from "@/calendar/components/event/EventMenu.vue";
import { getElementPosition } from "@/calendar/utils/ViewUtils";
import EventList from "@/calendar/components/event/EventList.vue";

export default {
  components: { EventList, EventMenu, EventEditorMenu },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    events: {
      type: Array,
      required: true
    },
    date: {
      type: String
    }
  },
  data() {
    return {
      showEventMenu: false,
      showEventEditor: false,
      headerClass:
        "mt-2 px-4 d-flex align-center text--primary text-subtitle-1 font-weight-medium"
    };
  },
  computed: {
    ...mapGetters("auth", ["getUserInfo"]),
    title() {
      const mNow = moment();
      const mDate = moment(this.date);
      return mDate.isSame(mNow, "year")
        ? mDate.format("Mo Do dddd")
        : mDate.format("LL");
    },
    userEvents() {
      if (!this.date) return [];
      const date = moment(this.date);

      return this.events.filter(e => {
        return (
          e.platform === EVENT_PLATFORM.SIRTEAM &&
          e.me &&
          date.isBetween(
            this.getFormattedDate(e.start),
            this.getFormattedDate(e.end),
            undefined,
            "[]"
          )
        );
      });
    },
    sharedEvents() {
      if (!this.date) return [];
      const date = moment(this.date);

      return this.events.filter(e => {
        return (
          (e.calendar.type === CALENDAR_TYPE.SUBSCRIPTION ||
            e.calendar.type === CALENDAR_TYPE.PUBLIC_SUBSCRIPTION) &&
          date.isBetween(
            this.getFormattedDate(e.start),
            this.getFormattedDate(e.end),
            undefined,
            "[]"
          )
        );
      });
    },
    organEvents() {
      if (!this.date) return [];

      const date = moment(this.date);

      return this.events.filter(e => {
        return (
          e.calendar.type === CALENDAR_TYPE.ORGANIZATION &&
          date.isBetween(
            this.getFormattedDate(e.start),
            this.getFormattedDate(e.end),
            undefined,
            "[]"
          )
        );
      });
    },
    googleEvents() {
      if (!this.date) return [];
      const date = moment(this.date);

      return this.events.filter(e => {
        return (
          e.platform === EVENT_PLATFORM.GOOGLE &&
          date.isBetween(
            this.getFormattedDate(e.start),
            this.getFormattedDate(e.end),
            undefined,
            "[]"
          )
        );
      });
    }
  },
  methods: {
    ...mapMutations("cal", [
      "SET_EVENT_MENU",
      "SET_EDIT_EVENT",
      "SET_EVENT_EDITOR",
      "SET_ACTIVE_EVENT_EDITOR"
    ]),
    updateShow(value) {
      this.$emit("input", value);
    },
    getFormattedDate(value) {
      let year = value.getFullYear();
      let month = value.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let date = value.getDate();
      if (date < 10) {
        date = `0${date}`;
      }

      return `${year}-${month}-${date}`;
    },
    openEventMenu(nativeEvent, event) {
      nativeEvent.stopPropagation();
      if (this.showEventEditor) return;

      const open = () => {
        this.SET_EVENT_MENU({
          event,
          element: nativeEvent.target
        });
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.showEventMenu = true))
        );
      };

      if (this.showEventMenu) {
        this.showEventMenu = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    openEventEditor({ event, element, isExceptionRequest }) {
      this.SET_EVENT_MENU({
        event: null,
        element: null
      });
      this.SET_EVENT_EDITOR({
        ...getElementPosition(element),
        isExceptionRequest,
        fixed: true
      });
      this.SET_ACTIVE_EVENT_EDITOR("MoreDialog");

      const open = () => {
        requestAnimationFrame(() =>
          requestAnimationFrame(() => {
            this.SET_EDIT_EVENT(event);
            this.showEventEditor = true;
          })
        );
      };

      if (this.showEventEditor) {
        this.showEventEditor = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/simple-scrollbar.scss";
</style>
